@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide the default date picker icon */
  .no-default-date-icon::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .no-default-date-icon {
    -moz-appearance: textfield;
    appearance: none;
  }

  .no-default-date-icon::-ms-clear {
    display: none;
  }
}

@layer base {
  :root {
    --background: 198, 100%, 98%;
    --foreground: 205, 84%, 37%;

    --card: 0, 0%, 36%;
    --card-foreground: 197, 68%, 43%;

    --popover: 0, 0%, 92%;
    --popover-foreground: 205, 84%, 37%;

    --primary: 197, 68%, 43%;
    --primary-foreground: 198, 100%, 98%;

    --secondary: 0, 0%, 92%;
    --secondary-foreground: 197, 68%, 43%;

    --muted: 0, 0%, 71%;
    --muted-foreground: 205, 84%, 37%;

    --accent: 120, 86%, 46%;
    --accent-foreground: 197, 68%, 43%;

    --destructive: 0, 100%, 41%;
    --destructive-foreground: 198, 100%, 98%;

    --border: 0, 0%, 71%;
    --input: 0, 0%, 71%;
    --ring: 205, 84%, 37%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 205, 84%, 37%;
    --foreground: 198, 100%, 98%;

    --card: 205, 84%, 37%;
    --card-foreground: 198, 100%, 98%;

    --popover: 205, 84%, 37%;
    --popover-foreground: 198, 100%, 98%;

    --primary: 198, 100%, 98%;
    --primary-foreground: 197, 68%, 43%;

    --secondary: 0, 0%, 71%;
    --secondary-foreground: 198, 100%, 98%;

    --muted: 0, 0%, 71%;
    --muted-foreground: 205, 84%, 37%;

    --accent: 0, 0%, 71%;
    --accent-foreground: 198, 100%, 98%;

    --destructive: 0, 100%, 41%;
    --destructive-foreground: 198, 100%, 98%;

    --border: 0, 0%, 71%;
    --input: 0, 0%, 71%;
    --ring: 205, 84%, 37%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

#capturedShot {
  display: none !important;
}
.fade-enter {
  opacity: 0;
}
.Custom-click:active {
  border-color: #0f6bac;
  color: #0f6bac;
}

.zoom-button {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
/* Hover effect for the title */
.zoom-button:hover::before {
  content: attr(title);
  position: absolute;
  top: -40px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 25px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

/* Add this to your CSS file */
.tooltip-text {
  visibility: hidden;
  width: 130px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 84%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.no-truncate {
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
}

.note-taker.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
}
.date-input {
  color: rgba(91, 91, 91, 0.5);
  font-weight: 600;
}

/* Fade in effect for the tooltip */
.zoom-button:hover::before {
  opacity: 1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.PhoneInputInput {
  border-right: none !important;
  border-left: none !important;
  border: transparent;
  outline: none !important;
  --tw-ring-color: transparent !important;
  padding-bottom: 0%;
  padding-top: 0%;
}

._bnEditor_4vj2p_3 {
  /* padding-inline: 20px !important; */
  transform: translateY(-12px) !important;
}

._defaultStyles_12je0_34 {
  padding: 0px 14px !important;
}

.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.demo-dev {
  width: 100%;
  border-top-right-radius: 40px;
  background-size: cover;
  position: relative;
  top: 12vh;
  right: 0;
}
.loginScreen {
  background-image: url("../Assets/images/backGround.jpg");
}
.user-player {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
}
.wrapper {
  position: relative;
  object-fit: fill;
  width: 100%;
  height: 65vh;
  min-height: 525px;
  border-radius: 5px;
}

.guest-player {
  object-fit: contain;
  height: 30%;
  position: absolute;
  top: 65%;
  left: 75%;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  border-radius: 10px;
}

.callEnd {
  color: white;
}

.datepicker > input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 10px;
}

@media screen and (max-width: 1440px) {
  .wrapper > .guest-player {
    object-fit: contain;
    height: 25%;
    position: absolute;
    top: 65%;
    left: 50vw;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    border-radius: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper > .guest-player {
    object-fit: contain;
    height: 25%;
    position: absolute;
    top: 65%;
    left: 15vw;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    border-radius: 10px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper > .guest-player {
    object-fit: contain;
    height: 20%;
    position: absolute;
    top: 75%;
    left: 25vw;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    border-radius: 10px;
  }
}
.visits-create-modal {
  height: 92vh;
}
@media screen and (min-width: 768px) {
  .visits-create-modal {
    height: 100vh;
  }
}
.input-error {
  border: 1px solid red;
  color: red;
  font-weight: bold;
}
@media screen and (max-width: 420px) {
  .visit-card {
    flex-direction: column;
  }
  .visit-button {
    font-size: 12px;
    padding: 0px 5px;
  }
  .mobo-search-button {
    font-size: 12px;
  }
  .search-icon {
    width: 18px;
  }
}

.button-container {
  position: sticky;
  bottom: 0;
  background: white; /* Use a background to cover any content that might scroll under */
  z-index: 1000; /* Ensure it stays on top of other content */
  width: 100%; /* Full width */
  padding: 10px 0; /* Add some padding around the buttons */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for better separation */
}
.content-container {
  max-height: 90vh; /* Adjust based on your header/footer size */
  overflow-y: auto;
}

input[type="checkbox"].no-border-checkbox,
input[type="checkbox"].no-border-checkbox:focus,
input[type="checkbox"].no-border-checkbox:checked {
  outline: none;
  box-shadow: none;
}

/* Make sure to include any vendor-specific pseudo-elements for styling checkboxes */
input[type="checkbox"].no-border-checkbox::-moz-focus-inner,
input[type="checkbox"].no-border-checkbox:focus::-moz-focus-inner {
  outline: none;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 10px;
}

.custom-scrollbar2::-webkit-scrollbar {
  width: 13px;
  border-radius: 10px;
}

.custom-scrollbar2::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
}

/* Background gradient styling */
.gradient {
  background: linear-gradient(
    360deg,
    #12181d 12.82%,
    rgba(18, 24, 29, 0) 91.03%
  );
}
@media screen and (max-width: 400px) {
  .certifcate-text {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 800px) {
  .certifcate-text {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 975px) and (max-width: 1100px) {
  .certifcate-text {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .certifcate-text {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 400px) {
  .breadCrumb-text {
    font-size: 10px !important;
  }
}

.breadCrumb-text {
  word-break: break-all;
}
@media screen and (min-width: 400px) and (max-width: 534px) {
  .breadCrumb-text {
    font-size: 11px !important;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.8;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: gray !important;
  opacity: 1;
  display: block;
  width: 16px;
  height: 16px;
  border-width: thin;
  /* background-color: red; */
}

.lightbox-container {
  max-height: 80vh;
  overflow-y: auto;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* This maintains the aspect ratio (4:3 in this case) */
  height: 0;
  overflow: hidden;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.custom-datepicker .grid-cols-7 {
  gap: 0.8rem;
}

.no-border {
  border: none !important;
}

.scroller {
  z-index: 9999;
  overflow: auto;
}

.PhoneInputInput::placeholder {
  color: #c4c4c4 !important; /* Placeholder text color */
}
.PhoneInputInput:disabled::placeholder {
  color: #c4c4c4 !important; /* Placeholder text remains the same */
}

.PhoneInputInput {
  background: none !important;
  cursor: inherit;
}

.PhoneInputInput::placeholder {
  color: #c4c4c4 !important;
  background: none !important;
  opacity: 1;
  cursor: not-allowed !important;
}

.stories-container {
  display: flex;
  justify-content: center;
}

@keyframes slideIn {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.progress-bar-container {
  height: 4px;
  background-color: #fae495; /* Light yellow background */
  overflow: hidden; /* Ensures that the inner bar does not overflow the border */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.progress-bar {
  height: 4px;
  background-color: #facc15; /* Yellow color to match the warning theme */
  animation: slideIn 7s linear forwards; /* Adjust time as needed */
}

@media (min-width: 640px) {
  .scroller {
    overflow-y: visible;
  }
}


@layer base {
  /* Basic editor styles for elements inside a container with the "tiptap" class */
  
  /* Ensure the first child of the tiptap container has no top margin */
  .tiptap :first-child {
    margin-top: 0;
  }

  /* Placeholder (at the top) */
  .tiptap p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  /* Ensure paragraphs break words properly */
  .tiptap p {
    word-break: break-all;
  }

  /* Give a remote user a caret */
  .tiptap .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .tiptap .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
  }

  /* List styles for the editor */
  .tiptap .ProseMirror ul,
  .tiptap .ProseMirror ol {
    list-style-position: outside !important;
    padding-left: 1.5rem !important;
    margin-left: 0 !important;
  }

  /* Unordered list markers (bullets) */
  .tiptap .ProseMirror ul {
    list-style-type: disc !important;
  }

  /* Ordered list markers (numbers) */
  .tiptap .ProseMirror ol {
    list-style-type: decimal !important;
  }

  /* Force list items to render as native list items */
  .tiptap .ProseMirror li {
    display: list-item !important;
  }

  /* Ensure the list markers appear in black */
  .tiptap .ProseMirror ul li::marker,
  .tiptap .ProseMirror ol li::marker {
    color: black !important;
    font-size: inherit;
  }

  /* Optional: Additional spacing for lists within .tiptap */
  .tiptap ul,
  .tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }

  .tiptap ul li p,
  .tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

  /* Heading styles */
  .tiptap h1,
  .tiptap h2,
  .tiptap h3,
  .tiptap h4,
  .tiptap h5,
  .tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
  }
  .tiptap h1 {
    font-size: 1.4rem;
  }
  .tiptap h2 {
    font-size: 1.2rem;
  }
  .tiptap h3 {
    font-size: 1.1rem;
  }
  .tiptap h4,
  .tiptap h5,
  .tiptap h6 {
    font-size: 1rem;
  }

  /* Horizontal rule styles */
  .tiptap hr {
    border: none;
    border-top: 2px solid #0f6bac85;
    margin: 2rem 0;
  }

  /* Remove the default focus outline from the editor */
  .ProseMirror:focus {
    outline: none;
  }
}